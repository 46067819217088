import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import ElementUI from 'element-ui'; // 导入element-ui包
import JSEncrypt from 'jsencrypt'
import { Message } from 'element-ui';
import Cookies from 'js-cookie'
import md5 from 'js-md5';
import 'element-ui/lib/theme-chalk/index.css'; // 导入相关样式
import VueResource from 'vue-resource'
import Axios from 'axios'
import VueClipboard from 'vue-clipboard2'

// api地址
Vue.prototype.globalIpAddr = 'http://127.0.0.1:8085/';
//Vue.prototype.globalIpAddr = 'https://yl.doliit.com/';

Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.prototype.$bus = new Vue()
Vue.config.silent = true
Vue.config.productionTip = false
Vue.use(VueResource)
Vue.use(ElementUI);
Vue.prototype.$jsEncrypt = JSEncrypt
Vue.prototype.$md5 = md5;
// 保存后端传来的cookie
Axios.defaults.withCredentials = true

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

Vue.prototype.$getRsaCode = function (str) { // 注册加密方法
  const encryptStr = new JSEncrypt()
  const pubKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAprMBzZnig3sdH7yPVpDg
    ixQUQC0UNpNVjOTDtfWPK+0ofOtRVaTdjYw+++dUq3+4JYLv6bdVr2oieFmnJbYy
    kEW9E15PVkqUPIHUzpXBsi32/EK9M+/cDibmRveyaSzr4NksWVk+SN2fmcibadS3
    Iej2ZUO9T+fCQahS2TGVqnDE+2lCLlJHHhUtMm9hSUdhKy1KYwCv8PUlxvW3NCBV
    dEBOuCefvQdKeR1GmNGvCPBL+mzLJnQaX6K1gXs7BGKRvG0P/v2QmEpQGJowE9Ob
    yNYvWZ5d3nzgI8UABlZ6PAjPzeK38VnJSsO/TXy7umvB59FtgeEhl7ukOoNPltpn
    QQIDAQAB`
  encryptStr.setPublicKey(pubKey) // 设置 加密公钥
  const data = encryptStr.encrypt(str) // 进行加密
  return data
}
Vue.prototype.$getRsaDeCode = function (str) { // 解密
  var decrypt = new JSEncrypt()
  const pubKeyPrivate = `MIIEoQIBAAKCAQEAprMBzZnig3sdH7yPVpDgixQUQC0UNpNVjOTDtfWPK+0ofOtR
  VaTdjYw+++dUq3+4JYLv6bdVr2oieFmnJbYykEW9E15PVkqUPIHUzpXBsi32/EK9
  M+/cDibmRveyaSzr4NksWVk+SN2fmcibadS3Iej2ZUO9T+fCQahS2TGVqnDE+2lC
  LlJHHhUtMm9hSUdhKy1KYwCv8PUlxvW3NCBVdEBOuCefvQdKeR1GmNGvCPBL+mzL
  JnQaX6K1gXs7BGKRvG0P/v2QmEpQGJowE9ObyNYvWZ5d3nzgI8UABlZ6PAjPzeK3
  8VnJSsO/TXy7umvB59FtgeEhl7ukOoNPltpnQQIDAQABAoIBAB4kj0ZotZNVzzne
  ZELgdqHfcxv5qsaFlLoh4ZvE9+sW+rO/71ifs6o1Omxdc6uBMECkMRKfYqCR/gKq
  H3XLwM/itGJMsublHHHmfh8O6FP6SMdrn//EtxNeCFS71Gh9RXfGfWT4pLETVhk+
  M5AK9URt9iPwgEpzG2a486Yvd6M2bqrdfC8WeFNBPMa63VxbRexxE1XRFlX6jP54
  NvRO2F9QbTwpu3+ZNcJNiQjXvWft/QTKO+SBK5fKRiEtNBSOHwsKq/D/p8p5HNeZ
  TRLnU0V2b8hzvP7kVOvnxid7rT96T2A3ODFyPUxCvh7xd88MEscMMOlpZy0sXHE/
  icMYP7kCgYEAtY35I4eJQLWxKLRFLW9Lo3IV0Iz1PNW/pu6//5JxgCauPdTgtDgI
  M/qnRFKDdnSi1rvnaZGbNdcdX0y9Zm3O6M65dqK2VacKQH0Y85SScreduaLIhrw7
  b+hu8duUET2+fpbeXYuEsJGf7KDLAH50mBa+65swG02G7Ssh1uzF+n0CgYEA6w2m
  O5uoGtE3HbxrjExKN4CUZaEcDD6md1ZXlLBoy0wxPh8Vi0jA4vuN8qtjayAa7lHs
  5XAOj9o8K8IclYZnKt/nW+MQkdtsS8bLBs9/wrVlEEhAeKROYd8vxkS6rqhuGBYt
  4AFkqUlxilWwPEEvXa58yBibpaOIAv2Jm8a8NxUCfyZLzEGyVUFREg2D+ROcZlgT
  uIZFNXWfoM2VjupZ+ZcOkbJ5NOzO5OwR8zZ2MUTLVY32FNov3I1+0rNHfKwefYae
  FIDx0YQ/t+nieWDn9fw8vwhpTeMd0WvJm51os9LvMz+Ak2coBtU34oQHZsqz4eQy
  6BLKqVsY/10HvBuZ1ikCgYEApswWzHHWxsD0JavRfcudVultZPkA2EeVBVZF7AOL
  OciloPmZ5i8IgYoU3sF0WU59DeUhAGeWTcqFrF+gvRKVHz2oBfKJjZdxsAwvgnO4
  q8jhIL7E0Vd3L8ibNE0NVGe9SGZkw3L5pY+yfIH1/6waGpZ5gdzEhrQXDlgwUFSk
  t0kCgYBnq/VwV3uI8sjNSneTLt9qsrE8fbr75Yq6ijJeMxofbFQWw1va3QpnTGvf
  fYJIKgI2VQ2LQDO7QmYv80kpXklPmg3SSXRVB7LF3TlnKA90zdKef4AW7sqV3Uv6
  64aYv8dzRYtuua+qwOYY0AqzPJYAXr+TawDV2sXpRdjGCpE59g==`// ES6 模板字符串 引用 rsa 公钥
  try {
    decrypt.setPrivateKey(pubKeyPrivate)//解密公钥
    var decryptMsg = decrypt.decrypt(str) //解密
  }
  catch {
    Message({
      showClose: true,
      message: '您的账户信息不匹配请重新登录*',
      type: 'error'
    });
    Cookies.remove('_t')
  }
  return decryptMsg
}
Vue.prototype.$getRsaDeCodeelse = function (str) { // 解密
  var decrypt = new JSEncrypt()
  const pubKeyPrivate = `MIIEoQIBAAKCAQEAprMBzZnig3sdH7yPVpDgixQUQC0UNpNVjOTDtfWPK+0ofOtR
  VaTdjYw+++dUq3+4JYLv6bdVr2oieFmnJbYykEW9E15PVkqUPIHUzpXBsi32/EK9
  M+/cDibmRveyaSzr4NksWVk+SN2fmcibadS3Iej2ZUO9T+fCQahS2TGVqnDE+2lC
  LlJHHhUtMm9hSUdhKy1KYwCv8PUlxvW3NCBVdEBOuCefvQdKeR1GmNGvCPBL+mzL
  JnQaX6K1gXs7BGKRvG0P/v2QmEpQGJowE9ObyNYvWZ5d3nzgI8UABlZ6PAjPzeK3
  8VnJSsO/TXy7umvB59FtgeEhl7ukOoNPltpnQQIDAQABAoIBAB4kj0ZotZNVzzne
  ZELgdqHfcxv5qsaFlLoh4ZvE9+sW+rO/71ifs6o1Omxdc6uBMECkMRKfYqCR/gKq
  H3XLwM/itGJMsublHHHmfh8O6FP6SMdrn//EtxNeCFS71Gh9RXfGfWT4pLETVhk+
  M5AK9URt9iPwgEpzG2a486Yvd6M2bqrdfC8WeFNBPMa63VxbRexxE1XRFlX6jP54
  NvRO2F9QbTwpu3+ZNcJNiQjXvWft/QTKO+SBK5fKRiEtNBSOHwsKq/D/p8p5HNeZ
  TRLnU0V2b8hzvP7kVOvnxid7rT96T2A3ODFyPUxCvh7xd88MEscMMOlpZy0sXHE/
  icMYP7kCgYEAtY35I4eJQLWxKLRFLW9Lo3IV0Iz1PNW/pu6//5JxgCauPdTgtDgI
  M/qnRFKDdnSi1rvnaZGbNdcdX0y9Zm3O6M65dqK2VacKQH0Y85SScreduaLIhrw7
  b+hu8duUET2+fpbeXYuEsJGf7KDLAH50mBa+65swG02G7Ssh1uzF+n0CgYEA6w2m
  O5uoGtE3HbxrjExKN4CUZaEcDD6md1ZXlLBoy0wxPh8Vi0jA4vuN8qtjayAa7lHs
  5XAOj9o8K8IclYZnKt/nW+MQkdtsS8bLBs9/wrVlEEhAeKROYd8vxkS6rqhuGBYt
  4AFkqUlxilWwPEEvXa58yBibpaOIAv2Jm8a8NxUCfyZLzEGyVUFREg2D+ROcZlgT
  uIZFNXWfoM2VjupZ+ZcOkbJ5NOzO5OwR8zZ2MUTLVY32FNov3I1+0rNHfKwefYae
  FIDx0YQ/t+nieWDn9fw8vwhpTeMd0WvJm51os9LvMz+Ak2coBtU34oQHZsqz4eQy
  6BLKqVsY/10HvBuZ1ikCgYEApswWzHHWxsD0JavRfcudVultZPkA2EeVBVZF7AOL
  OciloPmZ5i8IgYoU3sF0WU59DeUhAGeWTcqFrF+gvRKVHz2oBfKJjZdxsAwvgnO4
  q8jhIL7E0Vd3L8ibNE0NVGe9SGZkw3L5pY+yfIH1/6waGpZ5gdzEhrQXDlgwUFSk
  t0kCgYBnq/VwV3uI8sjNSneTLt9qsrE8fbr75Yq6ijJeMxofbFQWw1va3QpnTGvf
  fYJIKgI2VQ2LQDO7QmYv80kpXklPmg3SSXRVB7LF3TlnKA90zdKef4AW7sqV3Uv6
  64aYv8dzRYtuua+qwOYY0AqzPJYAXr+TawDV2sXpRdjGCpE59g==`// ES6 模板字符串 引用 rsa 公钥
  decrypt.setPrivateKey(pubKeyPrivate)//解密公钥
  var decryptMsg = decrypt.decrypt(str) //解密
  return decryptMsg
}

